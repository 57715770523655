import React from "react";
import { Helmet } from "react-helmet";

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/services/Hero";
import Content from "../sections/about/Content";
import FAQ from "../sections/services/FAQ"
import Approach from "../sections/services/Approach"
import CTA from "../sections/nightshift/CTA";
import Content1 from "../sections/landing3/Content1" 
import Content2 from "../sections/landing3/Content2"
import Content3 from "../sections/landing3/Content3"
import CenteredLight from "../sections/services/CenteredLight"
import Feature from "../sections/landing3/Feature" 
import VideoCTA from "../sections/landing3/VideoCTA"

const Services = () => {
  return (
    <>
      <Helmet>
        <title>NightShift | Consulting Services</title>
      </Helmet>
      <PageWrapper>
        <Hero />
        <Approach />
        <FAQ />
        <CTA />
      </PageWrapper>
    </>
  );
};
export default Services;
