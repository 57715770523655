import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";

const SectionStyled = styled(Section)``;

const FaqCard = ({ title, children, ...rest }) => (
  <Box {...rest}>
    <Title variant="card" mb={4} fontSize="24px" fontWeight="900" color="light">
      {title}
    </Title>
    <Text variant="small" color="lightShade">
      {children}
    </Text>
  </Box>
);

const Approach = () => (
  <>
    <SectionStyled bg="dark">
      <Container>
            <div>
              <Title color="light">
                Our Approach
              </Title>
              <Text color="lightShade">
                With the influx of Platform and Containerization solutions proliferating
                rapidly across the I.T. industry, not every project is successful despite
                 heavy investment in modernization. Why?
              </Text>
              <br />
              <Text color="lightShade">
                Our perspective allows us to look beyond platform implementation and into 
                fundamental application architecture to unleash the full value of cloud-native solutions. 
                Examples of this include successfully implementing a distributed consensus protocol to 
                support fault-tolerant, flexible single-master operations in the cloud (replacing a legacy 
                single-master design causing downtime), designing a modern gRPC/HTTP2 API implementation 
                to succesfully meet the demands of a rapidly growing digital service,  and re-designing a monolithic application 
                reliant on configuration files, into a microservices-oriented architecture enabling 
                secure developer self-service, independent project release cycles,
                 and a dramatic increase in development velocity.
              </Text>
              <br />
              <Text color="lightShade">
                These aspects of a digital transformation strategy are critical to the holistic 
                success of a modernization effort. We envision the modern Digital Enterprise to be 
                a place where tickets are opened to discuss tangible business use cases, such as
                 production load-testing on faster and more expensive storage types, vs the inefficient 
                 nature of these activities to support simple configuration changes or access requests.
                 We believe there is a better way and our mission is to design it, achieve it, and deliver it. 
              </Text>
            </div>
      </Container>
    </SectionStyled>
  </>
);

export default Approach;

