import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";

const SectionStyled = styled(Section)``;

const FaqCard = ({ title, children, ...rest }) => (
  <Box {...rest}>
    <Title variant="card" mb={4} fontSize="24px" fontWeight="900" color="light">
      {title}
    </Title>
    <Text variant="small" color="lightShade">
      {children}
    </Text>
  </Box>
);

const Centered = () => (
  <>
    <SectionStyled bg="light">
      <Container>
        <Row className="justify-content-center">
          <Col xl="7" lg="8" md="9">
            <div className="text-center">
              <Text color="darkShade">
                Despite our best efforts to anticipate and encapsulate the implementation needs of 
                our customers in advance, the expertise of an experienced consultant can mean 
                the difference between success and failure. NightShift Services focuses on unlocking
                 the full value and elasticity of cloud-native architecture, offering the experience 
                 of a boutique consulting engagement focused on core engineering and cloud-native 
                 fundamentals, backstopped by a network of technologists knowledgeable 
                 in the very latest in container platform and other emerging technologies. 
              </Text>
            </div>
          </Col>
        </Row>
      </Container>
    </SectionStyled>
  </>
);

export default Centered;

