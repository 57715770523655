import React from "react";
import styled from "styled-components";
//import { saturate, lighten } from "polished";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Button, Section, Box, Text } from "../../components/Core";

import { device } from "../../utils";
import imgHeroTab from "../../assets/nightshift/logo-color-no-bg.png";

const SectionStyled = styled(Section)`
  background-color: ${({ theme }) => `${theme.colors.black}`};
`;

const ImgRight = styled(Box)`
  position: relative;
  @media ${device.md} {
    position: absolute;
    top: 0;
    max-width: 100%;
    min-width: 100%;
    right: 3em;
    left: auto;
    transform: translateX(25%);
    padding-left: 420px;
  }
`;

const Hero = () => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <SectionStyled className="position-relative">
        <div className="pt-5"></div>
        <Container>
          <Row className="justify-content-center align-items-center position-relative">
            <Col md="5" className="order-md-2 position-static">
              <Text mb={4} color="lightShade" opacity={0.7}>
              Despite our best efforts to anticipate and encapsulate the implementation needs of our customers in advance, 
              the expertise of an experienced consultant can mean the difference between success and failure. 
              NightShift Services focuses on unlocking the full value and elasticity of cloud-native architecture, 
              offering the experience of a boutique consulting engagement focused on core engineering and cloud-native fundamentals, 
              backstopped by a network of technologists knowledgeable in the very latest in container platform and other emerging technologies.
               </Text>
            </Col>
            <Col md="7" className="order-md-1">
              <div
                data-aos="fade-right"
                data-aos-duration="750"
                data-aos-delay="500"
                data-aos-once="true"
              >
                <Box py={[null, null, null, 5]} pr={5} pt={[4, null]}>
                  <Title variant="hero" color="light">
                    NightShift Services
                  </Title>
                </Box>
              </div>
            </Col>
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};

export default Hero;
