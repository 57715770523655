import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";

const SectionStyled = styled(Section)``;

const FaqCard = ({ title, children, ...rest }) => (
  <Box {...rest}>
    <Title variant="card" mb={4} fontSize="24px" fontWeight="900" color="light">
      {title}
    </Title>
    <Text variant="small" color="lightShade">
      {children}
    </Text>
  </Box>
);

const FAQ = () => (
  <>
    <SectionStyled bg="black">
      <Container>
        <Row className="justify-content-center">
          <Col xl="7" lg="8" md="9">
            <div className="text-center">
              <Title color="light">
                Our Delivery Culture
              </Title>
              <Text color="lightShade">
                What makes a NightShift Services engagement different?
              </Text>
            </div>
          </Col>
        </Row>
        <Row className="pt-4">
          <Col lg="6" className="mt-5">
            <FaqCard title="Documentation-by-Default">
              Every NightShift Services engagement comes with a thorough engagement journal, detailing decisions 
              made through the journey, tracking every deliverable, made available during the engagement on-demand.
              We even ship hosted documentation as fast as we ship applications, providing 
              up-to-the-minute project status to stakeholders where desired. 
            </FaqCard>
          </Col>
          <Col lg="6" className="mt-5">
            <FaqCard title="Outcome Oriented">
              NightShift Services is committed to delivering high-value results. We believe a solution 
              is not valuable until the value it offers can be fully realized. As a result, valuable outcomes 
              guide everything we do. Expect more than traditional staff-augmentation.
            </FaqCard>
          </Col>
          <Col lg="6" className="mt-5">
            <FaqCard title="Innovation Driven">
              Doing things the way they've always been done will result in 
              exactly where things are. NightShift rewards nontraditional thought
              and technique, and passes that innovation on to add value to an engagement outcome.
            </FaqCard>
          </Col>
          <Col lg="6" className="mt-5">
            <FaqCard title="Agile Software Delivery">
            NightShift is committed to the four pillars of Agile Software Delivery.
            <br></br><em>Working software is more valuable than comprehensive docuementation.</em>
            <br></br><em>Individuals and interactions are more valuable than process and tools.</em>
            <br></br><em>Customer Collaboration is more valuable than contract negotiation.</em>
            <br></br><em>Responding to change is more valuable than following a plan.</em>
            </FaqCard>
          </Col>
        </Row>
      </Container>
    </SectionStyled>
  </>
);

export default FAQ;
