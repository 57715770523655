import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Button, Section, Text } from "../../components/Core";

import imgContent from "../../assets/image/png/content3-img.png";

const SectionStyled = styled(Section)`
  border-bottom: 1px solid #242427;
`;

const Content3 = () => (
  <>
    <SectionStyled bg="dark">
      <Container>
        <Row className="justify-content-center align-items-center">
          <Col lg="5" className="offset-lg-1 order-lg-2 mb-5 mb-lg-0">
            <div>
              <div
                data-aos="zoom-out"
                data-aos-duration="750"
                data-aos-once="true"
                data-aos-delay="500"
              >
                <img src={imgContent} alt="" className="img-fluid" />
              </div>
            </div>
          </Col>
          <Col
            md="10"
            lg="6"
            className="order-lg-1"
            data-aos="fade-right"
            data-aos-duration="750"
            data-aos-once="true"
          >
            <div>
              <Title color="light">
                NightShift makes products?
              </Title>
              <Text color="lightShade">
                Well.. sorta. Our unique approach to Enterprise-grade implementations-as-code lets us package service engagements in advance, and regularly test them for funtionality. When you purchase a package, you get an entire cloud environment, pre-configured for your requirements, on Day-0.
              </Text>
              <div className="mt-5">
                <Button>Order</Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </SectionStyled>
  </>
);

export default Content3;
